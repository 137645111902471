<template>
  <nav class="navbar navbar-inverse navbar-fixed-top" role="navigation">
    <div class="container">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>

        <router-link to="/Anasayfa" class="navbar-brand"  v-scroll-to="'#home'">
          <img class="brandLogo" src="../../assets/logo/logo3.png"
        /></router-link>
      </div>
      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul class="nav navbar-nav">
          <li>
            <a href="/Anasayfa">Anasayfa</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name :"Navbar"
};
</script>
<style scoped src="../../assets/MainPageAssets/css/bootstrap.css"></style>
<style scoped src="../../assets/MainPageAssets/css/style.css"> </style> 
<style scoped>

.brandLogo {
  height: 140%;
  width: 90%;
}
</style>